import React, { useState } from "react";
import { GrMail } from "react-icons/gr"
import axios from "axios";
import toast, { Toaster } from "react-hot-toast"


const Contact = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const sendEmail = async (e) => {
    e.preventDefault();

    // form clear
    e.target[0].value = ""
    e.target[1].value = ""
    e.target[2].value = ""

  

    try {
      await axios.post("/api/sendmail", {
        name, email, message
      })
      toast.success('Message delivered successfully', {
        style: {
          marginTop: '5rem',
          background: "#801f95",
          color: "#fff"
        }
      })
    } catch (err) {
      toast.error("An error occured", {
        style: {
          marginTop: '5rem',
          color: '#fff',
          background: "#801f95"
        }
      })
    }
  };

  
  return (
    <div className="contact">
      <Toaster position="top-center" reverseOrder={false} />
      <p className="contact__text">Contact me</p>
      <div className="contact__mail">
        <a className="contact__mail_a" href="mailto:info@ahmetboyraz.com">
          <span className="contact__mail_span">
            <GrMail />
          </span>
          info@ahmetboyraz.com
        </a>
      </div>
      <form className="contact__form" onSubmit={sendEmail}>
        <input
          placeholder="Enter your name"
          name="name"
          className="contact__form_input"
          type="text"
          required
          autoComplete="off"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          placeholder="Enter your email"
          name="email"
          className="contact__form_input"
          type="email"
          required
          autoComplete="off"
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          placeholder="Enter your message"
          name="message"
          className="contact__form_textarea"
          type="text"
          required
          autoComplete="off"
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="contact__form_div">
          <button className="contact__form_button">Send Message</button>
        </div>
      </form>
    </div>
  );
};

export default Contact;