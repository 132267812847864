import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/sorry.png";
import emotion from "../../Assets/Projects/sorry.png";
import Weather_App from "../../Assets/Projects/weather.png";
import Metaverse from "../../Assets/Projects/Metaverse.png";
import Sass_Portfolio from "../../Assets/Projects/Sass.png";
import stockapp from "../../Assets/Projects/stockapp.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Metaverse}
              isBlog={false}
              title="Metaverse"
              description="I wrote this project with next.js and got a little more familiar with next.js. I got out of my comfort zone and used next.js instead of react.js, but I still need to improve myself. "
              ghLink="https://github.com/AhmetBoyrazz/metaverse"
              demoLink="https://metaverse-bay-six.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={stockapp}
              isBlog={false}
              title="Stock App"
              description="The user can create an account and log in to his account. The account information is saved. I gained experience on how to work with a large number of components and how to deal with large projects."
              ghLink="https://github.com/AhmetBoyrazz/Stock-App"
              demoLink="https://stock-app-zeta.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Weather_App}
              isBlog={false}
              title="Weather App"
              description="Purpose of this project is to make a weather app using the api.openweathermap.org Api. It is to bring and display the weather conditions of the location desired by the user."
              ghLink="https://github.com/AhmetBoyrazz/Weather_App"
              demoLink="https://ahmetboyrazz.github.io/Weather_App/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Zzzz"
              description=""
              ghLink="#"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Sass_Portfolio}
              isBlog={false}
              title="Sass Portfolio"
              description="In this project, the portfolio website is designed using HTML and SASS (SCSS) codes for style. In this Sass/SCSS project, configured in separate files and folders in this project."
              ghLink="https://github.com/AhmetBoyrazz/Sass-Portfolio"
              demoLink="https://ahmetboyrazz.github.io/Sass-Portfolio/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Zzzz"
              description=""
              ghLink="#"
              demoLink="#"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
