import {IoLogoGameControllerB} from "react-icons/io";
import React from "react";
import Card from "react-bootstrap/Card";
import {MdTravelExplore} from "react-icons/md";
import {BiPodcast} from "react-icons/bi";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Ahmet Boyraz </span>
            from <span className="purple"> Stuttgart, Germany.</span>
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <IoLogoGameControllerB /> Playing Games
            </li>
            <li className="about-activity">
              <BiPodcast /> Listening Podcast
            </li>
            <li className="about-activity">
              <MdTravelExplore /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Never stop learning, because life never stops teaching."{" "}
          </p>
          <footer className="blockquote-footer">Ahmet B.</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
